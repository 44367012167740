import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const slug = route.paramMap.get('slug');

    if (slug === 'conventa-ljubljana-2025-infokit') {
      return this.router.createUrlTree(['/b2b/conventa-ljubljana-2025-infokit']);
    }

    return true;
  }
}