import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const he: LanguageSetting = {
  consent_modal: {
    title: 'אנחנו משתמשים בקובצי Cookie!',
    description:
      'אתר זה משתמש בקובצי Cookie חיוניים כדי להבטיח את פעילותו התקינה, וכן בקובצי Cookie למעקב כדי להבין כיצד אתם מתקשרים איתו. האחרונים יופעלו רק באישורכם. <button type="button" data-cc="c-settings" class="cc-link">קראו עוד</button>',
    primary_btn: {
      text: 'אישור',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'דחייה',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'הגדרות קובצי Cookie',
    save_settings_btn: 'שמור הגדרות',
    accept_all_btn: 'אשר הכול',
    reject_all_btn: 'דחה הכול',
    close_btn_label: 'סגור',
    cookie_table_headers: [{ col1: 'שם' }, { col2: 'דומיין' }, { col3: 'תוקף' }, { col4: 'תיאור' }],

    blocks: [
      {
        title: 'שימוש בקובצי Cookie',
        description: `אתר זה משתמש בקובצי Cookie כדי להבטיח פונקציונליות בסיסית ולשפר את חוויית הגלישה שלכם. קראו את <a href="/cookie-policy" class="cc-link">מדיניות קובצי ה-Cookie</a> המלאה.`,
      },
      {
        title: 'קובצי Cookie הכרחיים',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 ימים',
            col4: `משמש להבחנה בין בני אדם לרובוטים`,
          },
        ],
      },
    ],
  },
};

export const he_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `ניתן לקרוא את מדיניות העוגיות של Google Analytics בקישור הבא <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">כאן</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: 'חודש אחד',
      col4: `אוסף נתונים לסטטיסטיקות של האתר ומאפשר הצגת פרסומות ממומנות.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 חודשים',
      col4: `הגדרות המשמשות לשמירה על מזהה Google ייחודי כדי לזכור מידע אודות המשתמש כגון שפה מועדפת, מספר תוצאות לכל דף, והעדפות סינון SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 ימים',
      col4: `משמש לקישור פעילויות המשתמש במכשירים שונים כדי להציג פרסומות רלוונטיות יותר.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 ימים',
      col4: `שומר את מרכז השרתים המספק את השירות למשתמש, בשימוש בהקשר של איזון עומסים.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 שנה',
      col4: `שומר את ההסכמה לטיפול בעוגיות.`,
    },
  ],
};
