import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const fr: LanguageSetting = {
  consent_modal: {
    title: 'Nous utilisons des cookies !',
    description:
      'Ce site web utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ces derniers ne seront activés qu\'avec votre consentement. <button type="button" data-cc="c-settings" class="cc-link">En savoir plus</button>',
    primary_btn: {
      text: 'Accepter',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Refuser',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Paramètres des cookies',
    save_settings_btn: 'Enregistrer les paramètres',
    accept_all_btn: 'Tout accepter',
    reject_all_btn: 'Tout refuser',
    close_btn_label: 'Fermer',
    cookie_table_headers: [{ col1: 'Nom' }, { col2: 'Domaine' }, { col3: 'Expiration' }, { col4: 'Description' }],

    blocks: [
      {
        title: 'Utilisation des cookies',
        description: `Ce site web utilise des cookies pour assurer les fonctionnalités de base et améliorer votre expérience en ligne. Lisez la <a href="/cookie-policy" class="cc-link">politique de cookies</a> complète.`,
      },
      {
        title: 'Cookies strictement nécessaires',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 jours',
            col4: `Utilisé pour distinguer les humains des robots`,
          },
        ],
      },
    ],
  },
};

export const fr_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `La politique des cookies de Google Analytics peut être consultée via le <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">lien</a> suivant.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 mois',
      col4: `Il collecte des données pour les statistiques du site et permet d'afficher des annonces ciblées.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 mois',
      col4: `Il est utilisé pour stocker un identifiant Google unique afin de mémoriser les informations de l'utilisateur telles que la langue préférée, le nombre de résultats par page et les préférences du filtre SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 jours',
      col4: `Il est utilisé pour lier les activités de l'utilisateur sur différents appareils afin d'afficher des publicités plus pertinentes.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 jours',
      col4: `Il stocke quel centre de serveurs dessert l'utilisateur, utilisé en lien avec l'équilibrage de charge.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 ans',
      col4: `Il stocke le consentement pour le traitement des cookies.`,
    },
  ],
};
