import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const sl: LanguageSetting = {
  consent_modal: {
    title: 'Uporabljamo piškotke!',
    description:
      'To spletno mesto uporablja nujne piškotke za zagotovitev njegovega pravilnega delovanja ter sledilne piškotke za razumevanje vaše interakcije z njim. Slednji bodo nastavljeni samo ob vaši odobritvi. <button type="button" data-cc="c-settings" class="cc-link">Preberi več</button>',
    primary_btn: {
      text: 'Sprejmi',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Zavrni',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Nastavitve piškotkov',
    save_settings_btn: 'Shrani nastavitve',
    accept_all_btn: 'Sprejmi vse',
    reject_all_btn: 'Zavrni vse',
    close_btn_label: 'Zapri',
    cookie_table_headers: [{ col1: 'Ime' }, { col2: 'Domena' }, { col3: 'Potek' }, { col4: 'Opis' }],

    blocks: [
      {
        title: 'Uporaba piškotkov',
        description: `To spletno mesto uporablja piškotke za zagotavljanje osnovnih funkcij in izboljšanje vaše spletne izkušnje. Preberite celotno <a href="/cookie-policy" class="cc-link">politiko piškotkov</a>.`,
      },
      {
        title: 'Nujni piškotki',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 dni',
            col4: `Uporablja se za razlikovanje med ljudmi in roboti`,
          },
        ],
      },
    ],
  },
};

export const sl_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Politiko piškotkov za Google Analytics lahko preberete na naslednji <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">povezavi</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 mesec',
      col4: `Zbira podatke za statistiko spletnega mesta in omogoča prikazovanje ciljnih oglasov.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 mesecev',
      col4: `Uporablja se za shranjevanje edinstvene Google ID, ki si zapomni informacije o uporabniku, kot so jezik, število rezultatov na stran in nastavitve filtra SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 dni',
      col4: `Uporablja se za povezovanje uporabnikove dejavnosti na različnih napravah, da bi prikazoval bolj relevantne oglase.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 dni',
      col4: `Shranjuje, kateri strežniški center streže uporabniku, uporablja se v povezavi z uravnoteženjem obremenitve.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 let',
      col4: `Shranjuje soglasje za obdelavo piškotkov.`,
    },
  ],
};
