import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const sr: LanguageSetting = {
  consent_modal: {
    title: 'Koristimo kolačiće!',
    description:
      'Ova veb stranica koristi neophodne kolačiće za ispravan rad i kolačiće za praćenje kako bismo razumeli vašu interakciju sa njom. Oni će biti postavljeni samo uz vašu saglasnost. <button type="button" data-cc="c-settings" class="cc-link">Saznaj više</button>',
    primary_btn: {
      text: 'Prihvati',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Odbij',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Podešavanja kolačića',
    save_settings_btn: 'Sačuvaj podešavanja',
    accept_all_btn: 'Prihvati sve',
    reject_all_btn: 'Odbij sve',
    close_btn_label: 'Zatvori',
    cookie_table_headers: [{ col1: 'Naziv' }, { col2: 'Domen' }, { col3: 'Ističe' }, { col4: 'Opis' }],

    blocks: [
      {
        title: 'Upotreba kolačića',
        description: `Ova veb stranica koristi kolačiće kako bi obezbedila osnovne funkcionalnosti i poboljšala vaše korisničko iskustvo. Pročitajte punu <a href="/cookie-policy" class="cc-link">politiku kolačića</a>.`,
      },
      {
        title: 'Strogo neophodni kolačići',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 dana',
            col4: `Koristi se za razlikovanje ljudi i robota`,
          },
        ],
      },
    ],
  },
};

export const sr_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Polityku kolačića za Google Analytics možete pročitati na sledećem <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">linku</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 mesec',
      col4: `Prikuplja podatke za statistiku sajta i omogućava prikazivanje ciljanih oglasa.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 meseci',
      col4: `Koristi se za čuvanje jedinstvenog Google ID-a koji pamti informacije o korisniku, kao što su preferirani jezik, broj rezultata po stranici i postavke SafeSearch filtera.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 dana',
      col4: `Koristi se za povezivanje aktivnosti korisnika na različitim uređajima kako bi se prikazivali relevantniji oglasi.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 dana',
      col4: `Pamti koji server centr služi korisniku, koristi se u vezi sa balansiranjem opterećenja.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 godina',
      col4: `Pamti saglasnost za rukovanje kolačićima.`,
    },
  ],
};
