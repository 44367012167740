import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const ru: LanguageSetting = {
  consent_modal: {
    title: 'Мы используем файлы cookie!',
    description:
      'Этот веб-сайт использует основные файлы cookie для обеспечения его правильной работы, а также файлы cookie для отслеживания, чтобы понять, как вы с ним взаимодействуете. Последние будут установлены только с вашего согласия. <button type="button" data-cc="c-settings" class="cc-link">Подробнее</button>',
    primary_btn: {
      text: 'Принять',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Отклонить',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Настройки файлов cookie',
    save_settings_btn: 'Сохранить настройки',
    accept_all_btn: 'Принять все',
    reject_all_btn: 'Отклонить все',
    close_btn_label: 'Закрыть',
    cookie_table_headers: [{ col1: 'Название' }, { col2: 'Домен' }, { col3: 'Срок действия' }, { col4: 'Описание' }],

    blocks: [
      {
        title: 'Использование файлов cookie',
        description: `Этот веб-сайт использует файлы cookie для обеспечения базовой функциональности и улучшения вашего онлайн-опыта. Ознакомьтесь с полной <a href="/cookie-policy" class="cc-link">политикой использования файлов cookie</a>.`,
      },
      {
        title: 'Строго необходимые файлы cookie',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 дней',
            col4: `Используется для различения людей и роботов`,
          },
        ],
      },
    ],
  },
};

export const ru_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Политику использования cookies для Google Analytics можно прочитать по следующей <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">ссылке</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 месяц',
      col4: `Собирает данные для статистики сайта и позволяет показывать таргетированную рекламу.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 месяцев',
      col4: `Используется для хранения уникального идентификатора Google, который запоминает информацию о пользователе, такую как предпочтительный язык, количество результатов на странице и настройки фильтра SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 дней',
      col4: `Используется для связывания действий пользователя на разных устройствах, чтобы показывать более релевантную рекламу.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 дней',
      col4: `Хранит информацию о том, какой сервер обслуживает пользователя, используется в связи с балансировкой нагрузки.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 лет',
      col4: `Хранит согласие на обработку cookies.`,
    },
  ],
};
