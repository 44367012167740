import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const ro: LanguageSetting = {
  consent_modal: {
    title: 'Folosim cookie-uri!',
    description:
      'Acest site web utilizează cookie-uri esențiale pentru a asigura funcționarea corectă și cookie-uri de urmărire pentru a înțelege modul în care interacționați cu acesta. Acestea din urmă vor fi activate doar cu aprobarea dumneavoastră. <button type="button" data-cc="c-settings" class="cc-link">Citiți mai mult</button>',
    primary_btn: {
      text: 'Acceptă',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Respinge',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Setări cookie',
    save_settings_btn: 'Salvează setările',
    accept_all_btn: 'Acceptă toate',
    reject_all_btn: 'Respinge toate',
    close_btn_label: 'Închide',
    cookie_table_headers: [{ col1: 'Nume' }, { col2: 'Domeniu' }, { col3: 'Expirare' }, { col4: 'Descriere' }],

    blocks: [
      {
        title: 'Utilizarea cookie-urilor',
        description: `Acest site web utilizează cookie-uri pentru a asigura funcționalitățile de bază și pentru a îmbunătăți experiența dumneavoastră online. Citiți întreaga <a href="/cookie-policy" class="cc-link">politică privind cookie-urile</a>.`,
      },
      {
        title: 'Cookie-uri strict necesare',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 zile',
            col4: `Utilizat pentru a distinge între oameni și roboți`,
          },
        ],
      },
    ],
  },
};

export const ro_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Politica de cookies Google Analytics poate fi citită la următorul <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">link</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 lună',
      col4: `Colectează date pentru statistici ale site-ului și permite afișarea de anunțuri direcționate.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 luni',
      col4: `Este folosit pentru a stoca un ID Google unic pentru a memora informațiile utilizatorului, cum ar fi limba preferată, numărul de rezultate pe pagină și preferințele filtrului SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 zile',
      col4: `Este folosit pentru a lega activitățile utilizatorului pe diferite dispozitive pentru a afișa anunțuri mai relevante.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 zile',
      col4: `Stochează centrul de servere care servește utilizatorul, utilizat în legătură cu echilibrarea încărcării.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 ani',
      col4: `Stochează consimțământul pentru gestionarea cookie-urilor.`,
    },
  ],
};
