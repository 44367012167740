import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const es: LanguageSetting = {
  consent_modal: {
    title: '¡Usamos cookies!',
    description:
      'Este sitio web utiliza cookies esenciales para garantizar su correcto funcionamiento y cookies de seguimiento para comprender cómo interactúa con él. Estas últimas solo se activarán con su consentimiento. <button type="button" data-cc="c-settings" class="cc-link">Leer más</button>',
    primary_btn: {
      text: 'Aceptar',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Rechazar',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Configuración de cookies',
    save_settings_btn: 'Guardar configuración',
    accept_all_btn: 'Aceptar todas',
    reject_all_btn: 'Rechazar todas',
    close_btn_label: 'Cerrar',
    cookie_table_headers: [{ col1: 'Nombre' }, { col2: 'Dominio' }, { col3: 'Expiración' }, { col4: 'Descripción' }],

    blocks: [
      {
        title: 'Uso de cookies',
        description: `Este sitio web utiliza cookies para garantizar funcionalidades básicas y mejorar su experiencia en línea. Lea la <a href="/cookie-policy" class="cc-link">política de cookies</a> completa.`,
      },
      {
        title: 'Cookies estrictamente necesarias',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 días',
            col4: `Se utiliza para distinguir entre humanos y robots`,
          },
        ],
      },
    ],
  },
};

export const es_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `La política de cookies de Google Analytics se puede leer en el siguiente <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">enlace</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 mes',
      col4: `Recopila datos para las estadísticas del sitio y permite mostrar anuncios dirigidos.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 meses',
      col4: `Se utiliza para almacenar un ID único de Google para recordar la información del usuario, como el idioma preferido, el número de resultados por página y las preferencias de filtro de SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 días',
      col4: `Se utiliza para vincular las actividades del usuario en diferentes dispositivos para mostrar anuncios más relevantes.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 días',
      col4: `Almacena qué centro de servidores está sirviendo al usuario, se utiliza en relación con el balanceo de carga.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 años',
      col4: `Almacena el consentimiento para el manejo de cookies.`,
    },
  ],
};
