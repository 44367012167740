import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const cn: LanguageSetting = {
  consent_modal: {
    title: '我们使用 Cookie！',
    description:
      '本网站使用必要的 Cookie 以确保其正常运行，并使用跟踪 Cookie 来了解您的互动方式。后者仅在您同意后才会被设置。<button type="button" data-cc="c-settings" class="cc-link">了解更多</button>',
    primary_btn: {
      text: '接受',
      role: 'accept_all',
    },
    secondary_btn: {
      text: '拒绝',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Cookie 设置',
    save_settings_btn: '保存设置',
    accept_all_btn: '全部接受',
    reject_all_btn: '全部拒绝',
    close_btn_label: '关闭',
    cookie_table_headers: [{ col1: '名称' }, { col2: '域名' }, { col3: '过期时间' }, { col4: '描述' }],

    blocks: [
      {
        title: 'Cookie 使用情况',
        description: `本网站使用 Cookie 以确保基本功能并提升您的在线体验。阅读完整的<a href="/cookie-policy" class="cc-link">Cookie 政策</a>。`,
      },
      {
        title: '严格必要的 Cookie',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 天',
            col4: `用于区分人类和机器人`,
          },
        ],
      },
    ],
  },
};

export const cn_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Google Analytics 的 Cookie 政策可在以下 <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">链接</a> 中查看。`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 个月',
      col4: `收集网站统计数据，并用于投放定向广告。`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 个月',
      col4: `用于存储唯一的 Google ID，以记住用户信息，例如首选语言、每页搜索结果数量和安全搜索过滤器偏好。`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 天',
      col4: `用于将用户在不同设备上的活动关联起来，以显示更相关的广告。`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 天',
      col4: `存储为用户提供服务的服务器中心，与负载均衡相关联。`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 年',
      col4: `存储用户对 Cookie 处理的同意。`,
    },
  ],
};
