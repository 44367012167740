import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const it: LanguageSetting = {
  consent_modal: {
    title: 'Utilizziamo i cookie!',
    description:
      'Questo sito web utilizza cookie essenziali per garantirne il corretto funzionamento e cookie di tracciamento per comprendere come interagisci con esso. Questi ultimi saranno attivati solo con il tuo consenso. <button type="button" data-cc="c-settings" class="cc-link">Scopri di più</button>',
    primary_btn: {
      text: 'Accetta',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Rifiuta',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Impostazioni dei cookie',
    save_settings_btn: 'Salva impostazioni',
    accept_all_btn: 'Accetta tutti',
    reject_all_btn: 'Rifiuta tutti',
    close_btn_label: 'Chiudi',
    cookie_table_headers: [{ col1: 'Nome' }, { col2: 'Dominio' }, { col3: 'Scadenza' }, { col4: 'Descrizione' }],

    blocks: [
      {
        title: 'Utilizzo dei cookie',
        description: `Questo sito web utilizza i cookie per garantire le funzionalità di base e migliorare la tua esperienza online. Leggi l'intera <a href="/cookie-policy" class="cc-link">informativa sui cookie</a>.`,
      },
      {
        title: 'Cookie strettamente necessari',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 giorni',
            col4: `Utilizzato per distinguere tra esseri umani e robot`,
          },
        ],
      },
    ],
  },
};

export const it_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `La politica sui cookie di Google Analytics può essere letta al seguente <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">link</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 mese',
      col4: `Raccoglie dati per le statistiche del sito e consente di visualizzare annunci mirati.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 mesi',
      col4: `Viene utilizzato per memorizzare un ID Google unico per ricordare le informazioni dell'utente, come la lingua preferita, il numero di risultati per pagina e le preferenze del filtro SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 giorni',
      col4: `Viene utilizzato per collegare le attività dell'utente su dispositivi diversi per visualizzare annunci più pertinenti.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 giorni',
      col4: `Memorizza quale centro server sta servendo l'utente, utilizzato in relazione al bilanciamento del carico.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 anni',
      col4: `Memorizza il consenso per la gestione dei cookie.`,
    },
  ],
};
