import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const cs: LanguageSetting = {
  consent_modal: {
    title: 'Používáme soubory cookie!',
    description:
      'Tato webová stránka používá nezbytné soubory cookie k zajištění jejího správného fungování a sledovací soubory cookie, které pomáhají pochopit, jak s ní interagujete. Tyto soubory cookie budou nastaveny pouze po vašem souhlasu. <button type="button" data-cc="c-settings" class="cc-link">Zjistit více</button>',
    primary_btn: {
      text: 'Přijmout',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Odmítnout',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Nastavení souborů cookie',
    save_settings_btn: 'Uložit nastavení',
    accept_all_btn: 'Přijmout vše',
    reject_all_btn: 'Odmítnout vše',
    close_btn_label: 'Zavřít',
    cookie_table_headers: [{ col1: 'Název' }, { col2: 'Doména' }, { col3: 'Platnost' }, { col4: 'Popis' }],

    blocks: [
      {
        title: 'Použití souborů cookie',
        description: `Tato webová stránka používá soubory cookie k zajištění základních funkcí a zlepšení vašeho online zážitku. Přečtěte si celou <a href="/cookie-policy" class="cc-link">zásadu používání souborů cookie</a>.`,
      },
      {
        title: 'Nezbytně nutné soubory cookie',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 dní',
            col4: `Používá se k rozlišení mezi lidmi a roboty`,
          },
        ],
      },
    ],
  },
};

export const cs_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Cookie politika Google Analytics je k dispozici na následujícím <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link"> odkazu</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 měsíc',
      col4: `Shromažďuje data pro statistiky webu a umožňuje zobrazování cílených reklam.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 měsíců',
      col4: `Používá se k uložení jedinečného ID Google pro zapamatování informací o uživatelských preferencích, jako je preferovaný jazyk, počet výsledků na stránku a nastavení filtru SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 dní',
      col4: `Používá se k propojení aktivit uživatele na různých zařízeních pro zobrazování relevantnějších reklam.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 dní',
      col4: `Ukládá, který server poskytuje služby uživateli, používá se v souvislosti s vyvažováním zátěže.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 let',
      col4: `Ukládá souhlas s nakládáním s cookies.`,
    },
  ],
};
