import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const hr: LanguageSetting = {
  consent_modal: {
    title: 'Koristimo kolačiće!',
    description:
      'Ova web stranica koristi osnovne kolačiće kako bi osigurala ispravan rad te kolačiće za praćenje kako bismo razumjeli kako komunicirate s njom. Potonji će biti postavljeni samo uz vaše odobrenje. <button type="button" data-cc="c-settings" class="cc-link">Saznajte više</button>',
    primary_btn: {
      text: 'Prihvati',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Odbij',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Postavke kolačića',
    save_settings_btn: 'Spremi postavke',
    accept_all_btn: 'Prihvati sve',
    reject_all_btn: 'Odbij sve',
    close_btn_label: 'Zatvori',
    cookie_table_headers: [{ col1: 'Naziv' }, { col2: 'Domena' }, { col3: 'Istječe' }, { col4: 'Opis' }],

    blocks: [
      {
        title: 'Upotreba kolačića',
        description: `Ova web stranica koristi kolačiće kako bi osigurala osnovne funkcionalnosti i poboljšala vaše online iskustvo. Pročitajte cijelu <a href="/cookie-policy" class="cc-link">politiku kolačića</a>.`,
      },
      {
        title: 'Strogo nužni kolačići',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 dana',
            col4: `Koristi se za razlikovanje ljudi od robota`,
          },
        ],
      },
    ],
  },
};

export const hr_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Politiku kolačića za Google Analytics možete pročitati na sljedećem <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">linku</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 mjesec',
      col4: `Prikuplja podatke za statistiku web stranice i omogućava prikazivanje ciljanih oglasa.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 mjeseci',
      col4: `Koristi se za pohranu jedinstvene Google ID oznake za pamćenje korisničkih informacija, kao što su preferirani jezik, broj rezultata po stranici i postavke filtra SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 dana',
      col4: `Koristi se za povezivanje aktivnosti korisnika na različitim uređajima kako bi se prikazivali relevantniji oglasi.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 dana',
      col4: `Pohranjuje koji poslužitelj opskrbljuje korisnika, koristi se u vezi s balansiranjem opterećenja.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 godina',
      col4: `Pohranjuje pristanak za rukovanje kolačićima.`,
    },
  ],
};
