import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const en : LanguageSetting = {
  consent_modal: {
    title: 'We use cookies!',
    description:
      'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only uppon approval. <button type="button" data-cc="c-settings" class="cc-link">Read more</button>',
    primary_btn: {
      text: 'Accept',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Reject',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Cookie settings',
    save_settings_btn: 'Save settings',
    accept_all_btn: 'Accept all',
    reject_all_btn: 'Reject all',
    close_btn_label: 'Close',
    cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Expiration' }, { col4: 'Description' }],

    blocks: [
      {
        title: 'Cookie usage',
        description: `The website uses cookies to ensure the basic functionalities and to enhance your online experience. Read the full <a href="/cookie-policy" class="cc-link">cookie policy</a>.`,
      },
      {
        title:
        'Strictly necessary cookies',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 nap',
            col4: `Used to distinguish between humans and robots`,
          },
        ],
      },
    ],
  },
}

export const en_ga : BlockSetting = {
  title: 'Google analytics',
  description: `Google Analytics cookie policy can be read at the following <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">link</a>`,
  cookie_table: [
    // list of all expected cookies
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 month',
      col4: `It collects data for site statistics and allows to submit targeted ads.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 months',
      col4: `Settings are used to store a unique Google ID to remember user information such as preferred language,
       search results per page, and SafeSearch filter preferences.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 days',
      col4: `It is used to link the user's activities on different devices to display more relevant ads.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 days',
      col4: `It stores which server center is serving the user, used in connection with load balancing.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 years',
      col4: `Stores consent to the handling of cookies.`,
    },
  ],
}