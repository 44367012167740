import { ConsentConfig } from '@trendency/consent/lib/types/consent-config.types';
import { en }  from '../locales/cookie-consent/en';
import { de } from '../locales/cookie-consent/de';
import { cn } from '../locales/cookie-consent/cn';
import { cs } from '../locales/cookie-consent/cs';
import { es } from '../locales/cookie-consent/es';
import { fr } from '../locales/cookie-consent/fr';
import { he } from '../locales/cookie-consent/he';
import { hr } from '../locales/cookie-consent/hr';
import { it } from '../locales/cookie-consent/it';
import { ko } from '../locales/cookie-consent/ko';
import { pl } from '../locales/cookie-consent/pl';
import { ro } from '../locales/cookie-consent/ro';
import { ru } from '../locales/cookie-consent/ru';
import { sk } from '../locales/cookie-consent/sk';
import { sl } from '../locales/cookie-consent/sl';
import { sr } from '../locales/cookie-consent/sr';

export const consentConfig: ConsentConfig = {
  current_lang: 'en',
  autoclear_cookies: true,
  page_scripts: true,
  cookie_name: 'playground-cookie-consent',
  force_consent: true,
  revision: 1,
  gui_options: {
    consent_modal: {
      layout: 'bar',
      position: 'bottom right',
      transition: 'slide',
      swap_buttons: false,
    },
    settings_modal: {
      layout: 'box',
      transition: 'slide',
    },
  },
  languages: {
    en,
    de,
    cn,
    cs,
    es,
    fr,
    he,
    hr,
    it,
    ko,
    pl,
    ro,
    ru,
    sk,
    sl,
    sr
  },
}
