import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const ko: LanguageSetting = {
  consent_modal: {
    title: '쿠키를 사용합니다!',
    description:
      '이 웹사이트는 정상적인 작동을 보장하기 위해 필수 쿠키를 사용하며, 사용자와의 상호작용을 이해하기 위해 추적 쿠키를 사용합니다. 추적 쿠키는 사용자 승인 후에만 활성화됩니다. <button type="button" data-cc="c-settings" class="cc-link">자세히 보기</button>',
    primary_btn: {
      text: '수락',
      role: 'accept_all',
    },
    secondary_btn: {
      text: '거부',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: '쿠키 설정',
    save_settings_btn: '설정 저장',
    accept_all_btn: '모두 수락',
    reject_all_btn: '모두 거부',
    close_btn_label: '닫기',
    cookie_table_headers: [{ col1: '이름' }, { col2: '도메인' }, { col3: '만료' }, { col4: '설명' }],

    blocks: [
      {
        title: '쿠키 사용',
        description: `이 웹사이트는 기본 기능을 보장하고 온라인 경험을 향상시키기 위해 쿠키를 사용합니다. 전체 <a href="/cookie-policy" class="cc-link">쿠키 정책</a>을 읽어보세요.`,
      },
      {
        title: '엄격하게 필요한 쿠키',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179일',
            col4: `사람과 로봇을 구별하는 데 사용됩니다`,
          },
        ],
      },
    ],
  },
};

export const ko_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Google Analytics의 쿠키 정책은 다음 <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">링크</a>에서 확인할 수 있습니다.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1개월',
      col4: `사이트 통계를 위한 데이터를 수집하고 타겟 광고를 표시하는 데 사용됩니다.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6개월',
      col4: `사용자의 언어, 페이지당 검색 결과 수, SafeSearch 필터 설정과 같은 정보를 기억하기 위해 고유한 Google ID를 저장하는 데 사용됩니다.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390일',
      col4: `다른 장치에서 사용자의 활동을 연결하여 더 관련성 높은 광고를 표시하는 데 사용됩니다.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6일',
      col4: `사용자에게 서비스를 제공하는 서버 센터를 저장하며, 부하 분산과 관련이 있습니다.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20년',
      col4: `쿠키 처리에 대한 동의를 저장합니다.`,
    },
  ],
};
