import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const pl: LanguageSetting = {
  consent_modal: {
    title: 'Używamy plików cookie!',
    description:
      'Ta strona internetowa wykorzystuje niezbędne pliki cookie, aby zapewnić jej prawidłowe działanie, oraz pliki cookie śledzące, które pomagają nam zrozumieć, jak z niej korzystasz. Te drugie zostaną ustawione tylko za Twoją zgodą. <button type="button" data-cc="c-settings" class="cc-link">Czytaj więcej</button>',
    primary_btn: {
      text: 'Akceptuj',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Odrzuć',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Ustawienia plików cookie',
    save_settings_btn: 'Zapisz ustawienia',
    accept_all_btn: 'Akceptuj wszystkie',
    reject_all_btn: 'Odrzuć wszystkie',
    close_btn_label: 'Zamknij',
    cookie_table_headers: [{ col1: 'Nazwa' }, { col2: 'Domena' }, { col3: 'Wygasa' }, { col4: 'Opis' }],

    blocks: [
      {
        title: 'Wykorzystanie plików cookie',
        description: `Ta strona internetowa wykorzystuje pliki cookie, aby zapewnić podstawowe funkcjonalności i poprawić Twoje doświadczenie online. Przeczytaj pełną <a href="/cookie-policy" class="cc-link">politykę plików cookie</a>.`,
      },
      {
        title: 'Ściśle niezbędne pliki cookie',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 dni',
            col4: `Służy do odróżniania ludzi od robotów`,
          },
        ],
      },
    ],
  },
};

export const pl_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Politykę cookies Google Analytics można przeczytać pod następującym <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">linkiem</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 miesiąc',
      col4: `Zbiera dane do statystyk strony i umożliwia wyświetlanie ukierunkowanych reklam.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 miesięcy',
      col4: `Jest używane do przechowywania unikalnego identyfikatora Google, który zapamiętuje informacje użytkownika, takie jak preferowany język, liczba wyników na stronę oraz preferencje filtra SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 dni',
      col4: `Jest używane do łączenia aktywności użytkownika na różnych urządzeniach w celu wyświetlania bardziej odpowiednich reklam.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 dni',
      col4: `Przechowuje informację, który centrum serwerowe obsługuje użytkownika, wykorzystywane w związku z równoważeniem obciążenia.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 lat',
      col4: `Przechowuje zgodę na obsługę plików cookie.`,
    },
  ],
};
