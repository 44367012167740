import { Injectable } from '@angular/core';
import { ConsentBlockSetting, TrackingConfig, TrackingScript } from '@trendency/consent';
import { cn_ga } from 'src/assets/locales/cookie-consent/cn';
import { cs_ga } from 'src/assets/locales/cookie-consent/cs';
import { de_ga } from 'src/assets/locales/cookie-consent/de';
import { en_ga } from 'src/assets/locales/cookie-consent/en';
import { es_ga } from 'src/assets/locales/cookie-consent/es';
import { fr_ga } from 'src/assets/locales/cookie-consent/fr';
import { he_ga } from 'src/assets/locales/cookie-consent/he';
import { hr_ga } from 'src/assets/locales/cookie-consent/hr';
import { it_ga } from 'src/assets/locales/cookie-consent/it';
import { ko_ga } from 'src/assets/locales/cookie-consent/ko';
import { pl_ga } from 'src/assets/locales/cookie-consent/pl';
import { ro_ga } from 'src/assets/locales/cookie-consent/ro';
import { ru_ga } from 'src/assets/locales/cookie-consent/ru';
import { sk_ga } from 'src/assets/locales/cookie-consent/sk';
import { sl_ga } from 'src/assets/locales/cookie-consent/sl';
import { sr_ga } from 'src/assets/locales/cookie-consent/sr';
import { environment } from 'src/environments/environment';

const GA4_SCRIPT_ID = 'ga4-script';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService implements TrackingScript {
  consentTable: Record<string, ConsentBlockSetting> = {
    en: en_ga,
    cn: cn_ga,
    cs: cs_ga,
    de: de_ga,
    es: es_ga,
    fr: fr_ga,
    he: he_ga,
    hr: hr_ga,
    it: it_ga,
    ko: ko_ga,
    pl: pl_ga,
    ro: ro_ga,
    ru: ru_ga,
    sk: sk_ga,
    sl: sl_ga,
    sr: sr_ga
  };
  config: TrackingConfig = {
    value: 'ga',
    enabled: true,
    readonly: false,
  };
  loadScript(_isFirstAction: boolean): void {
    if (document.getElementById(GA4_SCRIPT_ID)) {
      return;
    }

    const script = document.createElement('script');
    script.id = GA4_SCRIPT_ID;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtmKey}`;
    script.onload = (): void => {
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());

            gtag('config', '${environment.gtmKey}', {'send_page_view': true});
          `;
      document.head.appendChild(gaScript);
    };

    document.head.appendChild(script);
  }

  removeScript(): void {
    const scriptElem = document.getElementById(GA4_SCRIPT_ID);
    if (!scriptElem) {
      return;
    }

    scriptElem.remove();
  }
}
