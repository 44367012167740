import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const sk: LanguageSetting = {
  consent_modal: {
    title: 'Používame súbory cookie!',
    description:
      'Táto webová stránka používa nevyhnutné súbory cookie na zabezpečenie jej správneho fungovania a sledovacie súbory cookie na pochopenie vašej interakcie s ňou. Posledné budú nastavené iba s vaším súhlasom. <button type="button" data-cc="c-settings" class="cc-link">Zistiť viac</button>',
    primary_btn: {
      text: 'Prijať',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Odmietnuť',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Nastavenia súborov cookie',
    save_settings_btn: 'Uložiť nastavenia',
    accept_all_btn: 'Prijať všetko',
    reject_all_btn: 'Odmietnuť všetko',
    close_btn_label: 'Zavrieť',
    cookie_table_headers: [{ col1: 'Názov' }, { col2: 'Doména' }, { col3: 'Expirácia' }, { col4: 'Popis' }],

    blocks: [
      {
        title: 'Používanie súborov cookie',
        description: `Táto webová stránka používa súbory cookie na zabezpečenie základných funkcií a zlepšenie vášho online zážitku. Prečítajte si celú <a href="/cookie-policy" class="cc-link">zásadu používania súborov cookie</a>.`,
      },
      {
        title: 'Nevyhnutné súbory cookie',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 dní',
            col4: `Používa sa na rozlíšenie medzi ľuďmi a robotmi`,
          },
        ],
      },
    ],
  },
};

export const sk_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Politiku cookies Google Analytics si môžete prečítať na nasledujúcom <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">linku</a>.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 mesiac',
      col4: `Zhromažďuje údaje pre štatistiky stránky a umožňuje zobrazenie cielených reklám.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 mesiacov',
      col4: `Používa sa na uloženie jedinečného ID Google, ktoré si pamätá informácie o používateľovi, ako je preferovaný jazyk, počet výsledkov na stránku a nastavenia filtra SafeSearch.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 dní',
      col4: `Používa sa na prepojenie aktivít používateľa na rôznych zariadeniach, aby sa zobrazovali relevantnejšie reklamy.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 dní',
      col4: `Ukladá, ktorý serverový uzol obsluhuje používateľa, používa sa v súvislosti s vyvažovaním záťaže.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 rokov',
      col4: `Ukladá súhlas so spracovaním cookies.`,
    },
  ],
};
