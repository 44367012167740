import { NgOptimizedImage } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { BaseModule } from './core/components/base/base.module';
import { FooterModule } from './core/components/footer/footer.module';
import { HeaderModule } from './core/components/header/header.module';
import { TokenInterceptor } from './features/priority-partner/token.interceptor';
import { DomainInterceptor } from './shared/interceptors/domain.interceptor';
import { SharedModule } from './shared/shared.module';
import { TrendencyHttpModule } from '@trendency/http';
import { TrendencyTranslationsRootModule } from '@trendency/translations';
import { TrendencyLoadingModule } from '@trendency/loading';
import { LoaderInterceptor } from '@trendency/http/interceptors/loader.interceptor';
import { RedirectGuard } from './features/static-page/b2b-redirect.guard';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    HttpClientModule,
    TrendencyHttpModule,
    RouterModule.forRoot(appRoutes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' }),
    TrendencyTranslationsRootModule.forRoot(appRoutes),
    BaseModule,
    HeaderModule,
    FooterModule,
    SharedModule,
    TrendencyLoadingModule,
    NgOptimizedImage,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DomainInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    RedirectGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
