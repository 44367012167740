import { BlockSetting, LanguageSetting } from "@trendency/consent/lib/types/consent.types";

export const de: LanguageSetting = {
  consent_modal: {
    title: 'Wir verwenden Cookies!',
    description:
      'Diese Website verwendet essenzielle Cookies, um ihre ordnungsgemäße Funktion zu gewährleisten, sowie Tracking-Cookies, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden nur nach Ihrer Zustimmung gesetzt. <button type="button" data-cc="c-settings" class="cc-link">Mehr erfahren</button>',
    primary_btn: {
      text: 'Akzeptieren',
      role: 'accept_all',
    },
    secondary_btn: {
      text: 'Ablehnen',
      role: 'accept_necessary',
    },
  },
  settings_modal: {
    title: 'Cookie-Einstellungen',
    save_settings_btn: 'Einstellungen speichern',
    accept_all_btn: 'Alle akzeptieren',
    reject_all_btn: 'Alle ablehnen',
    close_btn_label: 'Schließen',
    cookie_table_headers: [{ col1: 'Name' }, { col2: 'Domain' }, { col3: 'Ablauf' }, { col4: 'Beschreibung' }],

    blocks: [
      {
        title: 'Verwendung von Cookies',
        description: `Diese Website verwendet Cookies, um grundlegende Funktionen sicherzustellen und Ihr Online-Erlebnis zu verbessern. Lesen Sie die vollständige <a href="/cookie-policy" class="cc-link">Cookie-Richtlinie</a>.`,
      },
      {
        title: 'Unbedingt erforderliche Cookies',
        description: '',
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: [
          {
            col1: `_GRECAPTCHA`,
            col2: 'playground.com',
            col3: '179 Tage',
            col4: `Wird verwendet, um zwischen Menschen und Bots zu unterscheiden`,
          },
        ],
      },
    ],
  },
};

export const de_ga: BlockSetting = {
  title: 'Google Analytics',
  description: `Die Cookie-Richtlinie von Google Analytics kann unter folgendem <a
  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
  target="_blank" class="cc-link">Link</a> eingesehen werden.`,
  cookie_table: [
    {
      col1: `1P_JAR`,
      col2: 'Google',
      col3: '1 Monat',
      col4: `Sammelt Daten für die Website-Statistiken und ermöglicht das Schalten gezielter Werbeanzeigen.`,
    },
    {
      col1: `NID`,
      col2: 'Google',
      col3: '6 Monate',
      col4: `Speichert eine eindeutige Google-ID, um Benutzereinstellungen wie bevorzugte Sprache, Anzahl der Suchergebnisse pro Seite und SafeSearch-Filtereinstellungen zu merken.`,
    },
    {
      col1: `ANID`,
      col2: 'Google',
      col3: '390 Tage',
      col4: `Wird verwendet, um die Aktivitäten des Benutzers auf verschiedenen Geräten zu verknüpfen, um relevantere Anzeigen anzuzeigen.`,
    },
    {
      col1: `AWSALBCORS`,
      col2: 'starling.crowdrriff.com',
      col3: '6 Tage',
      col4: `Speichert, welches Serverzentrum den Benutzer bedient, und wird im Zusammenhang mit dem Lastenausgleich verwendet.`,
    },
    {
      col1: `CONSENT`,
      col2: 'Google',
      col3: '20 Jahre',
      col4: `Speichert die Zustimmung zur Verarbeitung von Cookies.`,
    },
  ],
};
